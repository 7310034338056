<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import EditProductForm from "@/components/drawers/product/editProductForm";
import ViewProductGallery from "@/components/drawers/product/viewProductGallery";
import productLogList from "@/components/drawers/product/productLogList";
import ManageBin from '@/components/drawers/product/manageBin.vue';
import AddItemsToBin from '@/components/drawers/product/addItemsToBin.vue';
import ViewBinItemsList from '@/components/drawers/product/viewBinItemsList.vue';
import AddIdentifiedItemsToBin from '@/components/drawers/product/AddIdentifiedItemsToBin.vue';
import IdentifierNewItemForm from '@/components/drawers/product/IdentifierNewItemForm.vue';
import {
  GetApiActionWithAuthorization,
  PostApiWithAuthorizationAction
} from "../../../helpers/apiActions";
import moment from 'moment-timezone';
import VLazyImage from "v-lazy-image/v2";
import tabMap from './tabMap';
import ProductCommentDrawer from "../../../components/drawers/product/productCommentForm.vue";
import { common } from '@/mixins/common'
import { isMobile, isTablet } from 'mobile-device-detect';
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Products"
  },
  components: {
    Layout,
    PageHeader,
    VLazyImage,
    EditProductForm,
    ViewProductGallery,
    ProductCommentDrawer,
    productLogList,
    ManageBin,
    AddItemsToBin,
    ViewBinItemsList,
    AddIdentifiedItemsToBin,
    IdentifierNewItemForm
  },
  data() {
    return {
      title: "Products",
      previewTemplateContent: "",
      selectedPreviewApp: "",
      displayProductLogs: false,
      manageProductComment: false,
      editProductDrawer: false,
      viewProductGalleryDrawer: false,
      viewPreviewTemplate: false,
      displayAddIdentifiedItemsToBinPopup: false,
      productLogs: [],
      previewStores: [],
      productPictures: [],
      items: [],
      productTags: [],
      productItemSpecifics: {},
      systemSetting: {},
      paginationOptions: [50, 100, 200],
      nextDrawerLabel: '',
      sortingOptions: [
        { value: "id", text: "ID"},
        { value: "binId", text: "BIN"},
        { value: "barcode", text: "Barcode" }
      ],
      sortingOrderOptions: [
        { value: "ASC", text: "ASC" },
        { value: "DESC", text: "DESC" }
      ],
      selectedAuctionId: 0,
      tabs: [
        {
          value: 'New',
          link: 'new',
          icon: 'fas fa-clipboard-list',
          active: false,
          count: 0
        },
        {
          value: 'Identified',
          link: 'identified',
          icon: 'fas fa-bullseye',
          active: false,
          count: 0
        },
        {
          value: 'Unbinned',
          icon: 'fas fa-sticky-note',
          link: 'unbinned',
          active: false,
          count: 0
        },
        {
          value: 'Archived',
          link: 'archived',
          icon: 'fas fa-trash',
          active: false,
          count: 0
        },
      ],
      columns: [
        {
          key: "id",
          value: '#',
          sortable: false,
        },
        {
          key: "details",
          value: 'Product Details',
          sortable: false,
          center: true
        },
        {
          key: "comment",
          value: 'Tags & Comments',
          sortable: false,
        },
        {
          key: "action",
          value: 'Action',
          sortable: false,
          center: true
        }
      ],
      formFilter: {
        tab: '',
        pageSize: 100,
        page: 1,
        lister: '',
        owner: '',
        store: '',
        application: '',
        tag: '',
        storeCategory: '',
        sortBy: '',
        searchAttribute: 'ID',
        searchQuery: '',
        sortingOrderOption: 'DESC',
        sortingOption: 'id'
      },
      productDetail: {
        title: '',
        description: '',
        selectedStoreCategory: [],
        AuctionAttachments: [],
        storeCategories: []
      },
      //begin filters data
      listers: [],
      users: {},
      tabOwners: [],
      owners: [],
      allOwners: [],
      stores: [],
      allStores: [],
      storeCategories: [],
      productStoreCategories: [],
      tags: [],
      applications: [],
      //end filters data
      tableData: [],
      tabCount: [],
      comments: [],
      featuredPresets: [],
      suggestionPresets: [],
      itemSpecifics: [],
      onPageTabLoad: true,
      loadingProductFormSpecific: false,
      displayManageBinPopup: false,
      displayAddItemsToBinPopup: false,
      displayBinItemPopup: false,
      displayAddIdentifiedItemsPopup: false,
      selectedBinId: 0
    };
  },
  computed: {
    hasMarkPresetStatus() {
      return ['review', 'flagged', 'submitted', 'unpaid', 'sold'].includes(this.formFilter.tab)
    },
    hasMarkReadStatus() {
      return this.formFilter.tab == 'review'
    },
    tabTitle () {
      return tabMap[this.formFilter.tab];
    },
    conversationDrawerSize () {
      return isMobile ? '100%' : isTablet ? '60%' : '40%'
    },
    pictureDrawerSize () {
      return isMobile ? '100%' : isTablet ? '60%' : '35%'
    },
    editProductDrawerSize () {
      return isMobile ? '100%' : isTablet ? '100%' : '95%'
    },
    ebayStoreUrl () {
      return process.env.NODE_ENV === "development" ? "http://sandbox.ebay.com" : "http://ebay.com"
    }
  },
  mixins: [
    common
  ],
  mounted() {
    let _vm = this
    //auto load table page size
    _vm.formFilter.pageSize = _vm.loadPageSize()
    //auto select default tab
    _vm.formFilter.tab = _vm.$route.query.tab || 'identified'
    let findActiveTabIndex = _vm.tabs.findIndex(m=>m.link==_vm.formFilter.tab) || 0
    _vm.$set(_vm.tabs, findActiveTabIndex, Object.assign(_vm.tabs[findActiveTabIndex], {
      active: true
    }));
    //open product drawer for editing stuff...
    _vm.$nextTick(() => {
      if (_vm.$route.name == 'product_edit') {
        _vm.loadPageData().then(() => {
          let productId = _vm.$route.params.id;
          if (productId)
            _vm.showProductDrawer(productId)
        })
      }
    })
    //
    _vm.$root.$on('make-preset', (auctionId) => {
      _vm.makePreset(auctionId)
    })
    _vm.$root.$on('load-item-specific-fields', (id) => {
      _vm.loadProductItemSpecifics(id)
    })
    _vm.$root.$on('delete-attachment', (id) => {
      _vm.productPictures = _vm.productPictures.filter(p=>p.id!=id)
    })
    _vm.$root.$on('display-edit-product-drawer', (display) => {
      _vm.editProductDrawer = display
    })
    _vm.$root.$on('display-product-drawer', (id) => {
      _vm.showProductDrawer(id)
    })
    _vm.$root.$on('display-product-log-drawer', (id) => {
      _vm.showProductLogDrawer(id)
    })
    _vm.$root.$on('display-manage-bin-drawer', (state) => {
      _vm.displayManageBinPopup = state
    })
    _vm.$root.$on('display-comment-drawer', (data) => {
      if (data.show) {
        _vm.showCommentDialog(data.id)
      } else {
        _vm.manageProductComment = data.show
      }
    })
    _vm.$root.$on('display-preview-picture-drawer', (data) => {
      if (data.show) {
        _vm.loadPreviewTemplate(data.id)
      } else {
        _vm.viewPreviewTemplate = data.show
      }
    })
    //load page data
    _vm.loadStores().then (() => {
      return Promise.all([
        _vm.load({ page: _vm.formFilter.page }),
        _vm.loadListers(),
        _vm.loadOwners(),
        _vm.loadStoreCategories(),
        _vm.loadProductTags(),
        _vm.loadPageData(),
        _vm.loadAllOwners(),
        _vm.loadAllStores()
      ])
    })
  },
  methods: {
    saveProductTag (data) {
        let _vm = this
        PostApiWithAuthorizationAction("admin/products/save-product-tag/" + data.id, {
          tags: data.tags
        }).then(res => {
          if (res.status == "success") {
            _vm.load()
          }
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        });
    },
    getProductApplication (id, key) {
      let findStore = this.previewStores.find(v=>v.value==id)
      return findStore ? findStore[key] : ""
    },
    loadWithNewPageSize() {
      localStorage.setItem('pageSize', this.formFilter.pageSize)
      this.load({ page: 1 })
    },
    loadProductPictures(id, length = 0) {
      if (length > 0) {
        let _vm = this
        GetApiActionWithAuthorization("admin/products/list-all-pictures/" + id).then(res => {
          if (res.status == "success") {
            _vm.productPictures = res.data
            //
            _vm.viewProductGalleryDrawer = true
          }
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        });
      }
    },
    loadProductItemSpecifics(categoryId) {
      let _vm = this
      _vm.loadingProductFormSpecific = true
      return GetApiActionWithAuthorization("admin/products/load-full-single-product-item-specifics/" + categoryId).then(data => {
        if (data.status == "success") {
          _vm.itemSpecifics = data.data
          _vm.loadingProductFormSpecific = false
          _vm.productItemSpecifics = data.data.map(i=>{
            i.formValue = _vm.productDetail.parameters[i.value];
            return i;
          }).reduce((obj, itm) => Object.assign(obj, { [itm.value]: itm.formValue }), {})
        } else {
          throw new Error(data.message)
        }
      }).catch(error => {
        // eslint-disable-next-line
        console.log(error)
      })
    },
    showProductLogDrawer(id) {
      let _vm = this
      return GetApiActionWithAuthorization("admin/products/logs/" + id).then(data => {
        if(data.status == "success") {
          _vm.displayProductLogs = true
          _vm.productLogs = data.data
        }
      });
    },
    showProductDrawer(id) {
      let _vm = this
      _vm.loadingProductFormSpecific = true
      window.history.replaceState({}, "Cybershop v2", '/products/edit/' + id);
      return GetApiActionWithAuthorization("admin/products/load-full-single-product-data/" + id).then(productData => {
        if(productData.status == "success") {
          let parameters = productData.data.ebayParameters
                            ? JSON.parse(productData.data.ebayParameters)
                            : {}
          _vm.productDetail = {
            ... parameters,
            ... productData.data,
            description: _vm.nl2br(parameters.description),
            hasPreset: productData.hasPreset
          }
          let selectedEbayApp = _vm.productDetail.ApplicationHasListedAuctions.filter(d=>d.Application.type=='ebay').map(v=>v.appId)
          _vm.productStoreCategories = productData.storeCategories.filter(v=>v.appId==(selectedEbayApp.length > 0 ? selectedEbayApp : 1))
          _vm.owners = productData.owners
          _vm.applications = productData.applications
          _vm.editProductDrawer = true
          return PostApiWithAuthorizationAction("admin/products/load-full-single-product-data-presets/" + _vm.productDetail.id, {
            id: productData.data.id,
            title: productData.data.title,
            description: parameters.description,
            category: parameters.category
          })
        } else {
          throw new Error(productData.message)
        }
      }).then(productPresets => {
        if (productPresets.status == "success") {
          _vm.featuredPresets = productPresets.data.featuredPresets
          _vm.suggestionPresets = productPresets.data.suggestionPresets
          return _vm.loadProductItemSpecifics(_vm.productDetail.category)
        } else {
          throw new Error(productPresets.message)
        }
      }).catch(error => {
        // eslint-disable-next-line
        console.log(error)
      }).finally(() => {
        _vm.loadingProductFormSpecific = false
      });
    },
    loadPreviewTemplate(id) {
      let _vm = this
      let url = "admin/products/preview-template/" + id
      _vm.selectedAuctionId = id
      let selectedApp = (typeof _vm.selectedPreviewApp == 'object') ? _vm.selectedPreviewApp.value : _vm.selectedPreviewApp
      GetApiActionWithAuthorization(url + "?appId=" + selectedApp).then(res => {
        _vm.previewTemplateContent = res.content
        // show modal
        _vm.viewPreviewTemplate = true
      }).catch(error => {
        _vm.$toast.error(error.message, {
          position: "top-right",
          timeout: 1000,
          closeOnClick: true
        });
      });
    },
    updateDomTabCount(tab = '', count = 1, increase = true) {
      let total = increase
                  ? parseInt(document.getElementById(tab + '-count').innerText) + count
                  : parseInt(document.getElementById(tab + '-count').innerText) - count;
      document.getElementById(tab + '-count').textContent = total
    },
    archiveProduct(auctionId) {
      let _vm = this
      GetApiActionWithAuthorization("admin/products/archive/" + auctionId).then(res => {
        if (res.status == "success") {
          const data = _vm.tableData
          const findIndex = data.findIndex(v=>v.id==auctionId)
          _vm.tableData = data.filter((v, i) => i !== findIndex)
          _vm.updateDomTabCount('archived');
          _vm.updateDomTabCount(_vm.formFilter.tab, 1, false)
          _vm.$toast.error(res.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        }
      }).catch(error => {
        // eslint-disable-next-line
        console.log(error)
        _vm.$toast.error(error.message, {
          position: "top-right",
          timeout: 1000,
          closeOnClick: true
        });
      });
    },
    markAsIdentified (auctionId) {
      if (confirm('Are you sure that you want to mark this items as Identified ?')) {
        let _vm = this
        GetApiActionWithAuthorization("admin/products/send-to-identified/" + auctionId).then(res => {
          if (res.status == "success") {
            const data = _vm.tableData
            const findIndex = data.findIndex(v=>v.id==auctionId)
            _vm.tableData = data.filter((v, i) => i !== findIndex)
            _vm.updateDomTabCount('identified');
            _vm.updateDomTabCount(_vm.formFilter.tab, 1, false)
            _vm.$toast.success(res.message, {
              position: "top-right",
              timeout: 1000,
              closeOnClick: true
            });
          }
        }).catch(error => {
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        });        
      }
    },
    sendToReview(auctionId) {
      let _vm = this
      GetApiActionWithAuthorization("admin/products/send-to-review/" + auctionId).then(res => {
        if (res.status == "success") {
          const data = _vm.tableData
          const findIndex = data.findIndex(v=>v.id==auctionId)
          _vm.tableData = data.filter((v, i) => i !== findIndex)
          _vm.updateDomTabCount('review');
          _vm.updateDomTabCount(_vm.formFilter.tab, 1, false)
          _vm.$toast.success(res.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        }
      }).catch(error => {
        // eslint-disable-next-line
        console.log(error)
        _vm.$toast.error(error.message, {
          position: "top-right",
          timeout: 1000,
          closeOnClick: true
        });
      });
    },
    makePreset (auctionId) {
      let _vm = this
      GetApiActionWithAuthorization("admin/products/copy-as-preset/" + auctionId).then(res => {
        if (typeof res.url !== 'undefined') {
          _vm.goToV1Url(res.url);
        }
      }).catch(error => {
        _vm.$toast.error(error.message, {
          position: "top-right",
          timeout: 1000,
          closeOnClick: true
        });
      });
    },
    getFormattedData(timestamp) {
      return moment(timestamp*1000).format('YYYY-MM-DD h:mm a')
    },
    searchProduct () {
      let _vm = this
      if (_vm.formFilter.searchQuery.length > 1) {
        _vm.load()
      } else {
        _vm.$toast.error("Minimum 2 characters required to find a product.", {
          position: "top-right",
          timeout: 1000,
          closeOnClick: true
        });
      }
    },
    onFilterProduct () {
      this.load()
    },
    clearFilterSelection () {
      this.formFilter = {
        ... this.formFilter,
        lister: '',
        owner: '',
        store: '',
        application: '',
        tag: '',
        storeCategory: ''
      }
      //
      this.load()
    },
    setSearchFilterType(type) {
      this.$set(this.formFilter, 'searchAttribute', type);
    },
    load (params = {}) {
      let _vm = this
      return PostApiWithAuthorizationAction("admin/products/all", {
        filter: Object.assign(_vm.formFilter, params)
      }).then(res => {
        _vm.productTags = res.productTags
        _vm.tableData = res.data.map(d=>{
          return {
            ... d,
            tags: !d.tags ? d.ProductHasTags.map(p=>parseInt(p.productTagId)) : d.tags
          }
        })
        _vm.tabCount[_vm.formFilter.tab] = res.count
      });
    },
    loadListers () {
      let _vm = this
      return GetApiActionWithAuthorization("admin/products/get-listers?filter[tab]=" + _vm.formFilter.tab).then(res => {
        _vm.listers = res.data
      });
    },
    loadOwners () {
      let _vm = this
      return GetApiActionWithAuthorization("admin/products/get-owners?filter[tab]=" + _vm.formFilter.tab).then(res => {
        _vm.tabOwners = res.data
      });
    },
    loadPageData () {
      let _vm = this
      return GetApiActionWithAuthorization("admin/products/get-page-data?filter[tab]=" + _vm.formFilter.tab).then(res => {
        _vm.tabCount = res.count
        _vm.systemSetting = res.setting
        _vm.users = res.users
      });
    },
    loadAllStores () {
      let _vm = this
      return GetApiActionWithAuthorization("admin/products/get-all-stores").then(res => {
        _vm.allStores = res.data
      });
    },
    loadAllOwners () {
      let _vm = this
      return GetApiActionWithAuthorization("admin/products/get-all-owners").then(res => {
        _vm.allOwners = res.data
      });
    },
    loadStores () {
      let _vm = this
      return GetApiActionWithAuthorization("admin/products/get-stores?filter[tab]=" + _vm.formFilter.tab).then(res => {
        _vm.stores = res.data
        if (res.data.length > 0) {
          _vm.selectedPreviewApp = { value: _vm.stores[0].id, text: _vm.stores[0].name}
        }
        _vm.previewStores = res.data.map(d => {
          return { value: d.id, text: d.name, color: d.color }
        })
      });
    },
    loadStoreCategories () {
      let _vm = this
      return GetApiActionWithAuthorization("admin/products/get-all-store-categories?filter[tab]=" + _vm.formFilter.tab).then(res => {
        _vm.storeCategories = res.data
      });
    },
    loadProductTags () {
      let _vm = this
      return GetApiActionWithAuthorization("admin/products/get-all-tags?filter[tab]=" + _vm.formFilter.tab).then(res => {
        _vm.tags = res.data
      });
    },
    onClose (e) {
      // prevent close and wait
      e.wait = true
      // close after 3 seconds
      setTimeout(() => {
        // assign true to close, do nothing or assign false to cancel close.
        e.close = true
      }, 3000)
    },
    showCommentDialog(auctionId)
    {
      let _vm = this
      _vm.loadComments(auctionId).then(data => {
        _vm.manageProductComment = true
        _vm.selectedAuctionId = auctionId
        _vm.comments = data
      })
    },
    preventTabClick(tab, oldTab) {
      if (typeof oldTab === 'string' && oldTab.length > 1) {
        location.href = '/identifier/index?tab=' + tab
      }
      return this.onPageTabLoad && (oldTab == 0 || !oldTab);
    },
    handleTabClick() {
      this.onPageTabLoad = false
      return this.onPageTabLoad;
    },
    resetToTabUrl() {
      window.history.replaceState({}, "Cybershop v2", '/identifier/index?tab=' + this.formFilter.tab);
    },
    handleStartBin() {
      this.displayManageBinPopup=true
      this.nextDrawerLabel = 'startIdentifiedBin'
    },
    handleManageBin() {
      this.displayManageBinPopup=true
      this.nextDrawerLabel = 'manageBin'
    },
    handleStartBinBulk() {
      this.displayManageBinPopup=true
      this.nextDrawerLabel = 'startIdentifiedBinBulk'
    },
    hasStore(index) {
      return this.getStores(index).length > 0
    },
    getStores(index) {
      let _vm = this
      const productStoreIds = _vm.tableData[index].ApplicationHasListedAuctions.map(v=>v.appId)
      return this.stores.filter(v=>v.type==='store' && productStoreIds.includes(v.id));
    }
  },
  watch: {
    'formFilter.page': function(newValue) {
      this.load({
        page: newValue
      })
    }
  }
};
</script>

<template>
  <div class="product-listing-page">
    <el-drawer
      ref="addIdentifiedItemsDrawer"
      :title="'NEW PRODUCT'"
      :visible.sync="displayAddIdentifiedItemsPopup"
      :close-on-press-escape="false"
      :with-header="true"
      :wrapperClosable="false"
      :size="smallDrawerSize"
      :append-to-body="true"
      :destroy-on-close="true"
      custom-class="common-drawer demo-drawer">
      <IdentifierNewItemForm
        :owners="allOwners"
        :applications="allStores"
        :systemSetting="systemSetting"/>
    </el-drawer>
    <el-drawer
      ref="addIdentifiedItemsToBinDrawer"
      :title="'BIN #' + formatBinNumber(selectedBinId)"
      :visible.sync="displayAddIdentifiedItemsToBinPopup"
      :close-on-press-escape="false"
      :with-header="true"
      :wrapperClosable="false"
      :size="smallDrawerSize"
      :append-to-body="true"
      :destroy-on-close="true"
      custom-class="common-drawer demo-drawer">
      <AddIdentifiedItemsToBin
        :binNumber="selectedBinId"
        :owners="allOwners"
        :applications="allStores"
        :systemSetting="systemSetting"/>
    </el-drawer>
    <el-drawer
      ref="addItemsToBinDrawer"
      :title="'Add Barcodes to B' + formatBinNumber(selectedBinId)"
      :visible.sync="displayAddItemsToBinPopup"
      :close-on-press-escape="false"
      :with-header="true"
      :wrapperClosable="false"
      :size="smallDrawerSize"
      :append-to-body="true"
      :destroy-on-close="true"
      custom-class="common-drawer demo-drawer">
      <AddItemsToBin :binNumber="selectedBinId"/>
    </el-drawer>
    <el-drawer
      ref="binItemDrawer"
      :title="'Bin Items: B' + formatBinNumber(selectedBinId)"
      :visible.sync="displayBinItemPopup"
      :close-on-press-escape="false"
      :with-header="true"
      :wrapperClosable="false"
      size="70%"
      :append-to-body="true"  
      custom-class="common-drawer demo-drawer">
      <ViewBinItemsList :binId="selectedBinId"/>
    </el-drawer>
    <el-drawer
      ref="manageBinDrawer"
      title="BIN Details"
      :visible.sync="displayManageBinPopup"
      :close-on-press-escape="false"
      :with-header="true"
      :wrapperClosable="false"
      :size="smallDrawerSize"
      :append-to-body="true"
      :destroy-on-close="true"
      custom-class="common-drawer demo-drawer">
      <ManageBin :next-drawer-label="nextDrawerLabel"/>
    </el-drawer>
    <el-drawer
      ref="logDrawer"
      title="Product Logs"
      :visible.sync="displayProductLogs"
      :close-on-press-escape="false"
      :size="conversationDrawerSize"
      :with-header="true"
      :wrapperClosable="false"  
      :append-to-body="true"  
      custom-class="common-drawer manage-product-log demo-drawer">
      <productLogList
        :data="productLogs"
        :users="users"/>
    </el-drawer>
    <!-- Picture Viewer Drawer -->
    <el-drawer
      title="Product Pictures"
      :visible.sync="viewProductGalleryDrawer"
      :close-on-press-escape="false"
      :size="pictureDrawerSize"
      :with-header="true"
      :wrapperClosable="false"  
      :append-to-body="true"  
      custom-class="common-drawer picture-gallery">
      <ViewProductGallery
        :pictures="productPictures"/>
    </el-drawer>
    <el-drawer
      ref="commentDrawer"
      title="Product Conversations"
      :visible.sync="manageProductComment"
      :close-on-press-escape="false"
      :size="conversationDrawerSize"
      :with-header="true"
      :wrapperClosable="false"  
      :append-to-body="true"  
      custom-class="common-drawer manage-product-comment demo-drawer">
      <ProductCommentDrawer
        :data="comments"
        :users="users"
        :productId="selectedAuctionId"/>
    </el-drawer>
    <el-drawer
      title="Preview Product Template"
      :visible.sync="viewPreviewTemplate"
      :close-on-press-escape="false"
      size="90%"
      :with-header="true"
      :wrapperClosable="false"  
      :append-to-body="true"  
      custom-class="common-drawer preview-template">
        <div style="font-size: 1rem !important">
          <div class="text-center" style="font-size: 1rem !important">
            <label class="d-inline-flex align-items-center">
              <span>Choose&nbsp;an&nbsp;application:</span>&nbsp;
              <b-form-select
                class="form-select form-select-lg"
                size="lg"
                v-model="selectedPreviewApp"
                :options="previewStores"
                @change="loadPreviewTemplate(selectedAuctionId)"
              ></b-form-select>&nbsp;
            </label>
          </div>
          <div
            class=""
            v-html="previewTemplateContent"></div>
        </div>
      </el-drawer>
    <Layout>
      <PageHeader
        :title="title"
        :subTitle="tabTitle"
        :items="items"
        icon="fas fa-gem" />
      <div class="auction-page-container">
        <div class="button-items mb-2">
          <el-link :underline="false" href="/identifier/create">
            <b-button variant="primary">
              <i class="bx bx-plus-circle font-size-16"></i> New Product
            </b-button>
          </el-link>
          <b-button
            variant="secondary"
            @click="handleStartBin()">
            <i class="bx bx-package font-size-16"></i> Start BIN
          </b-button>
          <b-button variant="success" @click="handleStartBinBulk()">
            <i class="fas fa-boxes"></i> Start BIN Bulk
          </b-button>
          <b-button variant="warning" @click="handleManageBin()">
            <i class="bx bx-box font-size-16"></i> Manage BIN
          </b-button>
        </div>
        <el-tabs
          type="border-card"
          v-model="formFilter.tab"
          :before-leave="preventTabClick"
          @tab-click="handleTabClick">
          <el-tab-pane
            v-for="tab in tabs"
            :key="tab.link"
            :active="tab.active"
            :name="tab.link"
          >
            <template #label>
              <a :href="'/identifier/index?tab=' + tab.link" class="product-tab-link">
                <i :class="['font-size-16', tab.icon]"></i> {{ tab.value }}
                <span 
                  :id="tab.link + '-count'"
                  class="tab-span-count">{{
                  tabCount[tab.link]
                }}</span>
              </a>
            </template>
            <div class="data-table" v-if="tab.link==formFilter.tab">
              <div class="table-responsive mb-0">
                <div class="filter-row text-center">
                  <div class="">
                    <el-form
                      ref="form"
                      :model="formFilter"
                      :inline="true"
                      class="form-inline-custom mb-0"
                    >
                      <!-- listers -->
                      <el-form-item>
                        <el-select
                          v-model="formFilter.lister"
                          :placeholder="'All Listers (' + listers.length + ')'"
                          @change="onFilterProduct('lister')"
                          size="small"
                          clearable
                        >
                          <el-option
                            :label="'All Listers (' + listers.length + ')'"
                            value=""
                          ></el-option>
                          <el-option
                            v-for="(option, index) in listers"
                            :key="index"
                            :label="option.first_name + ' ' + option.last_name"
                            :value="option.id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <!-- tabOwners -->
                      <el-form-item>
                        <el-select
                          v-model="formFilter.owner"
                          :placeholder="'All Owners (' + tabOwners.length + ')'"
                          @change="onFilterProduct('owner')"
                          size="small"
                          clearable
                        >
                          <el-option
                            :label="'All Owners (' + tabOwners.length + ')'"
                            value=""
                          ></el-option>
                          <el-option
                            v-for="(option, index) in tabOwners"
                            :key="index"
                            :label="option.displayName"
                            :value="option.id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <!-- Stores -->
                      <el-form-item>
                        <el-select
                          v-model="formFilter.store"
                          :placeholder="'All Stores (' + stores.length + ')'"
                          @change="onFilterProduct('store')"
                          size="small"
                          clearable
                        >
                          <el-option
                            :label="'All Stores (' + stores.length + ')'"
                            value=""
                          ></el-option>
                          <el-option
                            v-for="(option, index) in stores"
                            :key="index"
                            :label="option.name"
                            :value="option.id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <!-- Store Category -->
                      <el-form-item>
                        <el-select
                          v-model="formFilter.storeCategory"
                          :placeholder="'All Store Categories(' + storeCategories.length + ')'"
                          @change="onFilterProduct('storeCategory')"
                          size="small"
                          clearable
                        >
                          <el-option
                            :label="'All Store Categories(' + storeCategories.length + ')'"
                            value=""
                          ></el-option>
                          <el-option
                            v-for="(option, index) in storeCategories"
                            :key="index"
                            :label="option.name"
                            :value="option.id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <!-- Tags -->
                      <el-form-item>
                        <el-select
                          v-model="formFilter.tag"
                          :placeholder="'All Tags (' + tags.length + ')'"
                          size="small"
                          @change="onFilterProduct('tag')"
                          clearable
                        >
                          <el-option
                            :label="'All Tags (' + tags.length + ')'"
                            value=""
                          ></el-option>
                          <el-option
                            v-for="(option, index) in tags"
                            :key="index"
                            :label="option.tag"
                            :value="option.id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item>
                        <el-button
                          type="danger"
                          icon="fas fa-undo"
                          size="small"
                          circle
                          plain
                          @click="clearFilterSelection"
                        ></el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
            <div class="filter-query-form text-center">
                <el-form
                  ref="form"
                  :model="formFilter"
                  :inline="true"
                  @submit.native.prevent="searchProduct"
                  class="form-inline-custom mb-0"
                >
                  <el-form-item style="margin-right: 0" class="mb-0">
                    <el-dropdown @command="setSearchFilterType">
                      <el-button
                        type="success"
                        class="border-radius-empty border-width_1_5"
                      >
                        {{ formFilter.searchAttribute }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="ID">ID</el-dropdown-item>
                        <el-dropdown-item command="BIN">BIN</el-dropdown-item>
                        <el-dropdown-item command="Barcode">Barcode</el-dropdown-item>
                        <el-dropdown-item command="Title">Title</el-dropdown-item>
                        <el-dropdown-item command="Description">Description</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-form-item>
                  <el-form-item class="mr-0 border-green mb-0">
                    <el-input
                      :placeholder="'Find By ' + formFilter.searchAttribute"
                      class="border-empty"
                      v-model="formFilter.searchQuery"
                      clearable>
                    </el-input>
                  </el-form-item>
                  <el-form-item class="mb-0">
                    <el-button
                      type="success"
                      native-type="submit"
                      icon="el-icon-search"
                      class="border-radius-empty border-width_1_5"
                    ></el-button>
                  </el-form-item>
                  <el-form-item style="margin-left: 20px" class="mb-0">
                    <label class="d-inline-flex align-items-center">
                      <span>Sort&nbsp;By:</span>&nbsp;
                      <b-form-select
                        class="form-select form-select-sm"
                        size="sm"
                        v-model="formFilter.sortingOption"
                        @change="load()"
                      >
                      <b-form-select-option
                        v-for="sortingOption in sortingOptions"
                        :key="sortingOption.value"
                        :value="sortingOption.value">{{ sortingOption.text }}</b-form-select-option>
                      </b-form-select>&nbsp;
                      <b-form-select
                        class="form-select form-select-sm"
                        style="margin-right: 4px"
                        size="sm"
                        v-model="formFilter.sortingOrderOption"
                        @change="load()"
                      >
                      <b-form-select-option
                        v-for="sortingOrderOption in sortingOrderOptions"
                        :key="sortingOrderOption.value"
                        :value="sortingOrderOption.value">{{ sortingOrderOption.text }}</b-form-select-option>
                      </b-form-select>
                    </label>
                    <label class="d-inline-flex align-items-center">
                      <b-form-select
                        class="form-select form-select-sm"
                        size="sm"
                        v-model="formFilter.pageSize"
                        :options="paginationOptions"
                        @change="loadWithNewPageSize()"
                      ></b-form-select
                      >&nbsp;rows
                    </label>                  
                  </el-form-item>
                </el-form>
              </div>
                <table
                  role="table"
                  aria-busy="false"
                  aria-colcount="10"
                  class="table b-table"
                  style="background-color: rgba(0, 0, 0, 0.05); border: 5px solid #5b5f70"
                >
                  <thead
                    role="rowgroup"
                    class=""
                    style="background-color: #74788d; color: white"
                  >
                    <tr role="row" class="">
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex="1"
                        :class="[
                          column.center ? 'text-center': '',
                          'px-4'
                        ]"
                        v-for="column in columns"
                        :key="column.key"
                      >
                        <div>{{ column.value }}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <tr
                      role="row"
                      aria-rowindex="1"
                      class=""
                      v-for="(data, index) in tableData"
                      :key="data.id"
                    >
                      <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px">
                        <div class="">
                          <div class="mb-1">{{ data.id }}</div>
                          <div
                            class="mb-1"
                            style="cursor: pointer"
                            @click="showProductDrawer(data.id)">
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Product Barcode"
                              placement="left">
                              <span class="font-weight-bold" style="color: #08c">{{
                                data.barcode
                              }}</span>
                            </el-tooltip>
                          </div>
                          <div
                            style="cursor: pointer"
                            @click="showProductDrawer(data.id)">
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="BIN#"
                              placement="left">
                              <span class="font-weight-bold" style="color: #f46a6a">
                                B {{ data.binId == 0 ? "..." : data.binId }}
                              </span>
                            </el-tooltip>
                          </div>
                          <div class="mb-1">
                            <span
                              class="font-weight-bold"
                              style="color: #34c38f"
                              v-if="data.User"
                            >
                              {{ data.User.first_name }} {{ data.User.last_name[0] }}
                            </span>
                            <span
                              class="font-weight-bold"
                              style="color: #34c38f"
                              v-if="data.first_name"
                            >
                              {{ data.first_name }} {{ data.last_name[0] }}
                            </span>
                          </div>
                          <div class="">
                            <span
                              class="badge badge-pill font-size-14 badge-soft-success"
                              v-if="data.AuctionOwner"
                            >
                              {{ data.AuctionOwner.displayName }}
                            </span>
                            <span
                              class="badge badge-pill font-size-14 badge-soft-success"
                              v-if="data.displayName"
                            >
                              {{ data.displayName }}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td aria-colindex="3" role="cell" class="">
                        <div class="row" style="cursor: pointer">
                          <div
                            class="col-md-3"
                            @click="loadProductPictures(data.id, data.AuctionAttachments.length)">
                            <v-lazy-image
                              class="d-block w-100 img-thumbnail"
                              :src="data.AuctionAttachments[0].thumbnailUrl"
                              v-bind:src-placeholder="require('@/assets/images/image-lazy-loading.gif')"
                              v-if="data.AuctionAttachments.length > 0"
                              />
                            <span v-else>No Picture available</span>
                          </div>
                          <div class="col-md-9">
                            <div
                              :class="[
                                'font-weight-bold',
                                'single-product-title'
                              ]"
                              @click="showProductDrawer(data.id)">
                              {{ data.title }}
                            </div>
                            <div class="">
                              <span
                                class="badge badge-pill font-size-12 badge-soft-danger"
                                style="margin-right: 5px"
                                v-if="data.parameters.storeCategoryName1"
                                @click="showProductDrawer(data.id)"
                              >
                                {{ data.parameters.storeCategoryName1 }}
                              </span>
                              <span
                                class="badge badge-pill font-size-12 badge-soft-danger"
                                v-if="data.parameters.storeCategoryName2"
                                @click="showProductDrawer(data.id)"
                              >
                                {{ data.parameters.storeCategoryName2 }}
                              </span>
                              <span
                                style="float: right"
                                v-if="
                                  systemSetting.maxCharacterCount - data.title.length > 0
                                "
                              >
                                {{
                                  systemSetting.maxCharacterCount -
                                  data.title.length +
                                  " Characters left"
                                }}
                              </span>
                            </div>
                            <div
                              class="product-description"
                              v-if="data.parameters.description"
                              v-html="data.parameters.description.trim()"
                            ></div>
                            <div
                              class="mt-1">
                              <span
                                class="badge badge-pill font-size-12 badge-soft-danger"
                                v-for="(store, index) in data.ApplicationHasListedAuctions"
                                :style="{
                                  marginRight: '5px',
                                  backgroundColor: getProductApplication(store.appId, 'color') + ' !important',
                                  color: 'white'
                                }"
                                :key="index"
                              >
                                {{ getProductApplication(store.appId, 'text') }}
                              </span>
                            </div>
                            <div v-if="data.ebayId > 0 " class="mt-2 font-size-15">
                              <strong>Ebay ID:</strong>
                              <a :href="ebayStoreUrl + '/itm/' + data.ebayId" target="_NEW">
                              {{ data.ebayId }}
                              </a>
                            </div>
                            <div v-if="['submitted'].includes(formFilter.tab) && data.uniqueStoreId > 0" class="mt-2 font-size-15">
                              <strong v-if="hasStore(index)">Stores Links: </strong>
                                <a
                                  :href="store.productUrl + 'item/' + data.uniqueStoreId"
                                  target="_NEW"
                                  class="btn btn-sm btn-outline-danger btn-store-link mx-1"
                                  v-for="(store, _index) in getStores(index)"
                                  :key="_index">
                                  <b>{{ store.name }}</b>
                                </a>
                            </div>
                            <div class="font-size-15">
                              <span class="font-weight-bold">Creation Date:</span> {{ getFormattedData(data.createdOn) }}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        aria-colindex="4"
                        role="cell"
                        class="">
                        <div
                          class="comment"
                          v-for="(comment, index) in data.Comments"
                          :key="index">
                          <span
                            v-html="getCommentName(comment.userId)"
                            style="font-weight: bold; color: #08c;cursor: pointer;"></span>
                          : <span v-html="linkify(comment.message)"></span>
                        </div>
                        <b-row class="mb-1">
                          <b-col cols="10" class="px-0">
                            <el-select
                              v-model="data.tags"
                              placeholder="Set product Tag"
                              size="small"
                              multiple
                              @change="saveProductTag(data)"
                            >
                              <el-option
                                v-for="(productTag, index) in productTags"
                                :key="index"
                                :label="productTag.tag"
                                :value="productTag.id"
                              ></el-option>
                            </el-select>
                          </b-col>
                          <b-col cols="2" class="px-1">
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Manage Product Comment"
                              placement="top">
                              <el-button
                                type="warning"
                                icon="fas fa-comment"
                                size="small"
                                @click="showCommentDialog(data.id)"
                                circle>
                              </el-button>
                            </el-tooltip>
                          </b-col>
                        </b-row>
                      </td>
                      <td
                        aria-colindex="2"
                        role="cell"
                        :class="['text-center', 'px-0']">
                        <div class="mb-1">
                          <b-button
                            variant="primary"
                            @click="showProductDrawer(data.id)"
                            v-if="
                              [
                                'new',
                                'review',
                                'ready',
                                'flagged',
                                'submitted',
                                'unpaid',
                                'unsold',
                                'identified',
                                'identified_with_photo',
                                'sold',
                                'manual'
                              ].includes(formFilter.tab)
                            "
                          >
                            <i class="fas fa-edit"></i> Edit
                          </b-button>
                        </div>
                        <div class="mb-1">
                          <b-button
                            variant="danger"
                            size="sm"
                            @click="markAsIdentified(data.id)"
                            v-if="['new'].includes(formFilter.tab)"
                          >
                            <i class="fas fa-check-circle"></i> Mark as Identified
                          </b-button>
                        </div>
                        <b-dropdown
                          :dropleft="true"
                          variant="secondary"
                          v-if="!['not_relisted', 'new'].includes(formFilter.tab)">
                          <template slot="button-content">
                            <i class="fas fa-cog"></i>
                            <i class="mdi mdi-chevron-down"></i>
                          </template>
                          <b-dropdown-item
                            v-if="hasMarkPresetStatus"
                            variant="primary"
                            @click="makePreset(data.id)"
                          >
                            <i class="fab fa-pagelines"></i>&nbsp;&nbsp;&nbsp;Make Preset
                          </b-dropdown-item>
                          <b-dropdown-item
                            @click="sendToReview(data.id)"
                            v-if="[
                              'identified',
                              'identified_with_photo',
                              'queue',
                              'unpaid',
                              'flagged',
                              'sold',
                              'manual',
                              'archived'
                            ].includes(formFilter.tab)"
                          >
                            <i class="fas fa-search-dollar"></i>&nbsp;&nbsp;&nbsp;Send To Review
                          </b-dropdown-item>
                          <b-dropdown-item
                            @click="archiveProduct(data.id)"
                            v-if="[
                              'flagged', 
                              'unsold',
                              'queue',
                              'identified',
                              'identified_with_photo',
                              'manual'
                            ].includes(formFilter.tab)"
                          >
                            <i class="fas fa-trash-alt"></i>&nbsp;&nbsp;&nbsp;Archive
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-end">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="formFilter.page"
                        :total-rows="tabCount[tab.link]"
                        :per-page="formFilter.pageSize"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </Layout>
    <!-- Edit Product Information Drawer -->
    <el-drawer
      :title="'Edit Product: #' + productDetail.id"
      :visible.sync="editProductDrawer"
      :size="editProductDrawerSize"
      :with-header="true"
      :wrapperClosable="false"
      :destroy-on-close="true"
      custom-class="common-drawer edit-product"
      @closed="resetToTabUrl()">
      <EditProductForm
        :product="productDetail"
        :setting="systemSetting"
        :featuredPresets="featuredPresets"
        :suggestionPresets="suggestionPresets"
        :owners="owners"
        :tags="productTags"
        :applications="applications"
        :itemSpecifics="itemSpecifics"
        :productItemSpecifics = "productItemSpecifics"
        :loadingProductFormSpecific="loadingProductFormSpecific"
        :storeCategories="productStoreCategories"/>
    </el-drawer>
  </div>
</template>

<style>
.auction-page-container .modal-body {
  padding-right: 0;
  padding-left: 0;
}
.auction-page-container .el-input--small .el-input__inner {
  font-weight: 600;
}
.ck-content { height: fit-content !important; }
.ck-editor__editable { min-height: fit-content !important;}
.el-select {width: 100% !important;}
th:nth-child(3) {
  width: 25%;
}
.product-tab-link {
  padding: 10px 0;
  color: #909399 !important;
}
.el-tabs__item.is-active a.product-tab-link {
  color: #409eff !important;
}
#tab-review:not(.is-active) .product-tab-link,
#tab-flagged:not(.is-active) .product-tab-link {
  color: white !important;
}
</style>
