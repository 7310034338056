<script>
import {
    GetApiActionWithAuthorization,
    GetApiActionWithAuthorizationOnly, 
    PostApiWithAuthorizationAction 
} from '../../../helpers/apiActions'

export default {
  name: 'IdentifierNewItemForm',
  components: {},
  props: {
    binNumber: {
        type: [String, Number],
        required: true,
        default: ''
    },
    applications: {
      type: Array,
      required: false,
      default: () => []
    },
    owners: {
      type: Array,
      required: false,
      default: () => []
    },
    systemSetting: {
        type: Object,
        required: true
    },
    formats: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  data() {
    var checkBacodeNumber = (rule, value, callback) => {
        if (value.length == this.systemSetting.maxBarcodeCount) {
            const _vm = this
            GetApiActionWithAuthorizationOnly("admin/barcode/check-duplicate-barcode?barcode=" + _vm.form.barcode).then(res => {
                if (res.status == "error") {
                    callback(new Error('Duplicate barcode found'));
                }
                callback();
            });
        } else {
            callback(new Error('Barcode should have ' + this.systemSetting.maxBarcodeCount + ' characters.'));
        }
    };
    return {
        valid: true,
        form: {
            itemNumber: '',
            description: '',
            additionalDescription: '',
            barcode: '',
            binNumber: '',
            selectedOwner: 1,
            selectedEbayApp: [1],
            selectedStoreApp: '',
            selectedFormat: '',
            comment: ''
        },
        rules: {
            barcode: [
                { required: true, message: ' ', trigger: 'change' },
                { validator: checkBacodeNumber, trigger: 'change' }
            ],
            binNumber: [
                { required: true, message: ' ', trigger: 'change' },
                { min: 3, max: 3, message: ' ', trigger: 'change' }
            ]
        },
        stat: {
            barcodeEnteredCount: 0,
            barcodeSystemCount: 0
        },
        loadedBarcodeCount: false
    }
  },
  methods: {
    updateItemCount() {
        const _vm = this,
              binNumber = _vm.$route.query.bin || ''
        let url = "admin/products/identifier/get-item-count/" + binNumber
        return GetApiActionWithAuthorization(url)
        .then(res => {
            if (res.status == 'success') {
                _vm.form.itemNumber = '#' + (res.count+1)
            }
        });
    },
    autoSaveProduct() {
        let _vm = this
        if (_vm.form.binNumber.length > 3)
            _vm.form.binNumber = _vm.form.binNumber.slice(0, 3)
        if (_vm.form.barcode.length === _vm.systemSetting.maxBarcodeCount) {
            _vm.submitForm()
        }
    },
    submitForm() {
        let _vm = this
        _vm.$refs.formContainer.validate((valid) => {
            if (valid) {
                _vm.valid = false
                PostApiWithAuthorizationAction("admin/products/identifier/save", _vm.form)
                .then(res => {
                    if (res.status == 'success') {
                        /*_vm.resetForm({
                            itemNumber: '#' + (Number(_vm.form.itemNumber.replace('#', ''))+1),
                            binNumber: this.form.binNumber
                        })*/
                        let params = _vm.$route.query
                            params.mode = _vm.form.selectedFormat
                        window.location.href = window.location.href.split('?')[0] + '?' + Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&')
                        //_vm.valid = true
                    }
                });
            }
        });
    },
    resetForm(params = {}) {
        this.errors.clear();
        //
        this.form = Object.assign({
            description: '',
            additionalDescription: '',
            barcode: '',
            selectedOwner: 1,
            selectedEbayApp: [1],
            selectedStoreApp: ''
        }, params)
    },
    setFormat() {
        let _vm = this
        let format = _vm.formats[_vm.form.selectedFormat]
        _vm.form.description = format.description + '\n' + _vm.form.description
        _vm.form.additionalDescription = format.additionalDescription + '\n' + _vm.form.additionalDescription
        setTimeout(function() {
            _vm.$refs.description.focus()
        }, 100)
    }
  },
  computed: {
    ebayApps () {
      return this.applications.filter(v=>v.type==='ebay');
    },
    storeApps () {
      return this.applications.filter(v=>v.type==='store');
    },
    productCharCountColor () {
      let remaningCharacterLength = this.systemSetting.maxCharacterCount - this.form.description.length,
          color = '#5edc0a';
      if (remaningCharacterLength >= 19) {
        color = '#5edc0a';
      } else if (remaningCharacterLength >= 3) {
        color = '#08c';
      } else {
        color = 'red';
      }
      return color;
    }
  },
  mounted () {
    this.form.binNumber = this.binNumber
    this.updateItemCount()
  },
  watch: {
    binNumber: function(value) {
        this.form.binNumber = value
    },
    'form.description': function(value) {
        let characterLeft = this.systemSetting.maxCharacterCount - value.length
        if(characterLeft == 0) {
            this.$refs.additionalDescription.focus()
        }
    },
    formats: function(value) {
        if (value.length > 0) {
            if (this.$route.query.mode) {
                this.form.selectedFormat = parseInt(this.$route.query.mode)
            } else {
                this.form.selectedFormat = 0
            }
            this.setFormat()
        }
    },
  }
}
</script>

<template>
<div class="px-2" style="overflow: hidden">
    <el-form
        :model="form"
        @submit.native.prevent="submitForm"
        ref="formContainer"
        id="identifierForm">
        <b-row class="mb-2">
            <b-col cols="6">
                <b style="font-size: 25px; color: red;">PRODUCT {{ form.itemNumber }}</b>
            </b-col>
            <b-col cols="6">
                <el-select
                    v-model="form.selectedFormat"
                    placeholder="Mode"
                    size="medium"
                    style="width: 100%;"
                    @change="setFormat()"
                >
                    <el-option
                    v-for="(format, index) in formats"
                    :key="index"
                    :label="format.name"
                    :value="index"
                    ></el-option>
                </el-select>
            </b-col>
        </b-row>
        <el-form-item  class="mb-2">
            <label class="mb-0">Description</label>
            <span
            :style="
                'color: ' +
                productCharCountColor +
                ';font-size: calc(0.9em + 1vmin);font-weight: 600; font-style: italic;'
            "
            v-if="form.description.length > 0"
            >
            {{ (systemSetting.maxCharacterCount - form.description.length) + " Characters left" }}
            </span>
            <el-input
                resize
                type="textarea"
                rows="4"
                placeholder=""
                ref="description"
                :maxlength="systemSetting.maxCharacterCount"
                autofocus
                v-model="form.description">
            </el-input>
        </el-form-item>
        <el-form-item  class="mb-2">
            <label class="mb-0">Additional Description</label>
            <el-input
                resize
                type="textarea"
                rows="3"
                placeholder=""
                ref="additionalDescription"
                v-model="form.additionalDescription">
            </el-input>
        </el-form-item>
        <el-form-item
            class="mb-2"
            :rules="rules.binNumber"
            prop="binNumber"
            v-if="binNumber==''">
            <label class="mb-0">BIN#</label>
            <el-input
                type="number"
                placeholder=""
                v-model="form.binNumber"
                @input="autoSaveProduct()"
                maxlength="3"
                size="medium"
                show-word-limit>
            </el-input>
        </el-form-item>
        <el-form-item
            class="mb-2"
            :rules="rules.barcode"
            prop="barcode">
            <label class="mb-0">Barcode</label>
            <el-input
                placeholder=""
                v-model="form.barcode"
                :maxlength="systemSetting.maxBarcodeCount"
                size="medium">
            </el-input>
        </el-form-item>
        <el-form-item class="mb-2">
            <label class="mb-0">Ebay Application</label>
            <el-select
                v-model="form.selectedEbayApp"
                placeholder="Ebay App"
                size="medium"
                multiple
                :multiple-limit="1"
                style="width: 100%;"
            >
                <el-option
                v-for="(ebayApp, index) in ebayApps"
                :key="index"
                :label="ebayApp.name"
                :value="ebayApp.id"
                ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item class="mb-2">
            <label class="mb-0">Owner</label>
            <el-select
                v-model="form.selectedOwner"
                placeholder="# Owner"
                size="medium"
                style="width: 100%;"
            >
                <el-option
                v-for="(owner, index) in owners"
                :key="index"
                :label="owner.companyName"
                :value="owner.id"
                ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item  class="mb-2">
            <label class="mb-0">Note</label>
            <el-input
                resize
                type="textarea"
                rows="2"
                placeholder=""
                ref="comment"
                v-model="form.comment">
            </el-input>
        </el-form-item>
        <div class="mt-4">
            <el-button
                type="primary"
                native-type="submit"
                :disabled="!valid">
                Submit and Continue
            </el-button>
        </div>
    </el-form>
</div>
</template>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
}
</style>
<style>
    #identifierForm .el-form-item__content {
        line-height: 28px;
    }
</style>
